@use "@nationwide-bolt/css-core/dist/scss/tokens" as Bolt;

/* FORMS */
.sub-help-text {
    font-size: 14px;
}

.add-replace-car-icon,
.car-icon {
    width: 40px;
    height: 40px;
    background-image: url("../images/car-icon.svg");
    display: table-cell;
}

.vehicle-content {
    display: table-cell;
    vertical-align: middle;
    padding-left: 10px;
}

.margin-bottom-sm {
    margin-bottom: Bolt.$space-xs;
}

.margin-bottom-20 {
    margin-bottom: Bolt.$space-lg;
}

.margin-bottom-40.field-spacing {
    margin-bottom: 40px;
}

.margin-bottom-2xl.field-spacing,
.field-vin,
.margin-bottom-2xl {
    margin-bottom: 52px;
}

.margin-top-2xl.form-separator {
    margin-top: 52px;
}

.margin-top-lg {
    margin-top: Bolt.$space-lg;
}

.margin-top-xl {
    margin-top: Bolt.$space-2xl;
}

.margin-top-md {
    margin-top: Bolt.$space-sm;
}

.margin-bottom-xl {
    margin-bottom: Bolt.$space-2xl;
}

.mobile-number-text-box > div.ng-star-inserted {
    display: none;
}

.padding-left-zero {
    padding-left: 0;
}

.form-grouping {
    & .section-header {
        clear: both;
    }

    & .media,
    .col-margin {
        margin-left: 16px;
        margin-right: 16px;
    }

    &:after {
        clear: both;
    }
}

// BOLT Additions

.match-bolt-width {
    width: 350px;
}

.match-bolt-width.add-vehicle-vin {
    width: 345px;
}

@media screen and (max-width: 768px) {
    .match-bolt-width.add-vehicle-vin {
        width: 100%;
    }
}

.field-spacing is-personal-policy-alert-wrapper,
.field-spacing.margin-bottom-0 {
    margin-bottom: 0;
}

.padding-bottom-xl {
    padding-bottom: Bolt.$space-2xl;
}

bolt-modal is-interpolate {
    font-size: 18px;
    margin-bottom: 20px;
    display: inline-block;
}

bolt-modal is-personal-policy-alert-wrapper is-interpolate {
    margin-bottom: 0;
}

.bolt-typography .bolt-notification p {
    margin: 0;
}

.calendar-button {
    background-repeat: no-repeat;
    padding: 12px;

    & .bolt-button-wc-icon--position__left.bolt-button {
        margin-right: 0;
    }
}

.margin-top-md {
    margin-top: Bolt.$space-sm;
}

.margin-bottom-md {
    margin-bottom: Bolt.$space-sm;
}

.margin-bottom-lg {
    margin-bottom: Bolt.$space-lg;
}

.margin-bottom-2xl {
    margin-bottom: 52px;
}

@media screen and (min-width: 576px) {
    .margin-left-xl {
        margin-left: Bolt.$space-2xl;
    }
}

// BOLT Override
// This will make our lives sooo much easier
.nw-social-icon {
    width: 38px;
}

html {
    text-size-adjust: 100%;
}

h2,
.h2 {
    font-size: 32px;
}

.select-term-header {
    font-weight: normal;
    font-size: 32px;
    margin-bottom: Bolt.$space-2xl;
}

.bolt-typography a.btn {
    border-bottom: 1px solid rgb(204 204 204 / 85%);
    background-color: #f2f2f2;
    border-color: #ccc;
}

.bolt-typography .btn-default:hover,
.bolt-typography .btn-default:focus {
    color: #346094;
    background-color: #c7c7c7;
    border-color: #adadad;
}

.effectiveDateLabel {
    font-weight: bold;
    margin-bottom: 52px;
}

.bolt-typography {
    font-size: 18px;

    body {
        line-height: normal;
    }

    h2 {
        font-weight: normal;
    }

    p {
        margin: 0 0 10px;
    }

    strong,
    b {
        font-weight: bold;
    }

    .bolt-heading-lg,
    .bolt-heading-md,
    .bolt-heading-sm,
    .bolt-heading {
        + .bolt-heading-lg,
        + .bolt-heading-md,
        + .bolt-heading-sm {
            margin-top: inherit;
        }
    }

    p ~ .bolt-heading-lg,
    p ~ .bolt-heading-md,
    p ~ .bolt-heading-sm {
        margin-top: inherit;
    }

    a ~ ul,
    p ~ ul,
    p ~ ol {
        margin-bottom: inherit;
    }

    ul {
        list-style-position: inside;
        margin-top: 0;
        margin-bottom: 12.5px;
        padding-left: 10px;
    }

    ol {
        list-style-type: decimal;
        list-style-position: inside;
        margin-top: 0;
        margin-bottom: 12.5px;
    }

    ol[start] {
        margin-top: inherit;
    }

    li {
        margin-bottom: inherit;
        list-style-position: outside;
        text-indent: inherit;
        padding-left: initial;
        line-height: inherit;
    }

    ul ul,
    ol ul {
        list-style-type: circle;
        list-style-position: inside;
        margin: inherit;
    }

    ol ol,
    ul ol {
        list-style-type: lower-latin;
        list-style-position: inside;
        margin-left: inherit;
    }

    ul ul li,
    ol ul li {
        margin-bottom: inherit;
    }

    is-text-area {
        line-height: 30.6px;
    }
}

select.form-control {
    width: auto;
    max-width: 100%;

    &:not([size], [multiple]) {
        height: 39px;
    }
}

legend {
    font-size: 18px;
    font-weight: bold;
}

.clearfix {
    clear: both;
}

.radio-inline input[type="radio"] {
    vertical-align: middle;
    margin-bottom: 1px;
    margin-right: 5px;

    @media (max-width: em(450px)) {
        margin-top: 0;
    }
}

@media screen and (max-width: 28.125em) {
    legend {
        font-size: 18px;
    }
}

textarea.form-control.height-85 {
    font-size: 18px;
    height: 85px;
}

.radio-check-container {
    padding-bottom: 20px;
}

.radio-check-container legend {
    padding-bottom: 10px;
}

.field-subgroup {
    padding-left: 30px;

    &.col-12,
    &.col-sm-12 {
        padding-right: 30px;
    }
}

@media screen and (max-width: 768px) {
    .field-subgroup.field-spacing {
        padding-right: 0;
    }
}

.weight-normal {
    font-weight: normal;
}

.weight-bold {
    font-weight: bold;
}

.form-separator {
    border-top: 1px dotted #d1d1d1;
    margin: 0 0 20px;
}

.field-spacing .add-replace-year-make-model {
    margin-top: Bolt.$space-lg;
}

.field-spacing .replace-year-make-model {
    margin-bottom: Bolt.$space-lg;
}

.field-spacing.add-vehicle-vin {
    margin-bottom: 20px;
}

.field-spacing {
    margin-bottom: Bolt.$space-2xl;
    padding: 0;
}

.bolt-heading-md.add-replace-heading {
    margin-bottom: 52px;
    font-size: 32px;
    font-weight: 400;
}

@media screen and (max-width: 768px) {
    .bolt-heading-md.add-replace-heading {
        font-size: 20px;
        font-weight: 500;
    }

    .field-spacing .replace-year-make-model {
        margin-bottom: 52px;
    }
}

.field-vin {
    margin-bottom: 52px;
}

.help-block {
    margin-bottom: 5px;
}

.has-error {
    & .control-label {
        color: #000;
    }
}

@media screen and (max-width: 470px) {
    .field-subgroup {
        &.col-12,
        &.col-sm-12 {
            padding-right: 0;
        }
    }
}

@media screen and (max-width: 470px) {
    .form-separator {
        border-top: 1px dotted #d1d1d1;
        margin: 0 0 20px;
    }
}

.alert.alert-danger.nw-field-error {
    display: none;
}

.has-error .alert.alert-danger.nw-field-error {
    display: inline-block;
}

input[type="radio"],
input[type="checkbox"] {
    margin-right: 5px;
}

.input-group-addon.custom {
    border: none;
    color: #000;
    font-weight: bold;
    background: none;
    padding-left: 0;
    padding-right: 6px;
}

.input-group.custom .form-control:last-child,
.input-group-addon.custom:last-child {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

// still needed for the VIN field since it's not an actual bolt component
.bolt-textfield {
    background: #f6f6f6;
    border: 0;
    border-radius: 0;
    box-shadow: inset 0 -2px 0 0 #6c6c6c;
    box-sizing: border-box;
    color: #222;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.125rem;
    margin: 0;
    padding: 0.75rem;
    text-decoration: none;
    transition:
        background 350ms,
        border 350ms,
        color 350ms,
        box-shadow 350ms;
    z-index: 1;
    max-width: 100%;
}

.bolt-textfield:hover,
.bolt-textfield:focus {
    background: #eaeaea;
}

.has-error .bolt-textfield {
    box-shadow: inset 0 -2px 0 0 #eb0004;
}

.has-error span.checkmark,
.has-error span.stacked-checkmark {
    border: 1px solid #eb0004;
}

.has-error .input-group-addon {
    border-color: #a94442;
    background-color: #f2dede;
}

//**************************************************************************************************************************
.form-control.read-only {
    display: none;
}

.lease-label {
    margin-top: 20px;
}

.custom-vehicle > span p {
    display: inline;
}

is-icon {
    display: inline-block;
}

is-icon[size="sm"] {
    height: 16px;
    width: 16px;
}

is-icon,
is-icon *.is-icon {
    border: 0;
    font: inherit;
    font-weight: 400;
    margin: 0;
    padding: 0;
    text-align: left;
    vertical-align: baseline;
}

.bolt-icon-wc.is-icon {
    display: block;
}

.bolt-icon-wc--size-sm.is-icon {
    height: 16px;
    width: 16px;
}

.bolt-icon-wc--size-md.is-icon {
    height: 24px;
    width: 24px;
}

.bolt-icon-wc--size-lg.is-icon {
    height: 48px;
    width: 48px;
}

.bolt-icon-wc--size-2xl.is-icon {
    height: 60px;
    width: 60px;
}

.custom-height-width {
    bolt-icon {
        height: 18px;
        width: 18px;
    }
}

@media (min-width: 580px) {
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12 {
        float: left;
    }
}

.remove-bottom-margin {
    margin-bottom: 0;
}

.font-link {
    font-size: 14px;
}

.landing-page-header-text {
    font-size: 24px;
}

.field-group.field-spacing {
    margin-right: 30px;
}

.current-mortgage {
    font-size: 24px;
}

.mortgage-company-search {
    font-size: 28px;
}

.valuables-sub-help-text is-interpolate {
    font-size: 14px;
}

@media (min-width: 550px) {
    .bottom-element-desktop.field-spacing {
        margin-bottom: Bolt.$space-4xl;
    }
}

.font-bold {
    font-weight: bold;
}

@media screen and (max-width: 576px) {
    h2 {
        font-size: 28px;
    }
}

@media screen and (max-width: 450px) {
    .help-block {
        font-size: 12px;
    }
}

.other-text-area {
    line-height: 31px;
}

.margin-bottom-8 {
    margin-bottom: Bolt.$space-xs;
}

.field-spacing.other-text-val-plus is-interpolate {
    margin-bottom: 0;
}

.fill-icon-container {
    height: 100%;
    width: 100%
}

.update-nw-policy-message {
    margin-bottom: 52px;
    font-size: 16px;
}
