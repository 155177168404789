@use "@nationwide-bolt/css-core/dist/scss/tokens" as Bolt;
@import "mixins";

/*
| Policy Summary component
| Used on edit coverages pages, review/confirmation
|
*/

dl.bottom-padding {
    padding: 0 0 6px;
}

dl.top-padding {
    padding: 6px 0 0;
}

.info-container {
    margin: 52px 0;
}

.premium-per-term {
    color: Bolt.$palette-charcoal;
    font-size: 20px;
}

.premium-space-top {
    padding-top: 52px;
}

.policy-number {
    padding-left: 0;
}

.dashed-box .discounts-content-simple {
    margin-bottom: 22px;

    p {
        padding: 0;
    }
}

@media (max-width: 950px) {
    .policy-number {
        padding-left: 15px;
    }
}
