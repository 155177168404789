@import "_mixins";
/*
| Edit Coverages Full Page
| Styles for overall page outside of components
|
*/
/*
| Coverage alert  and premium boxes
| Display as columns within a single block
|
*/

.coverages__premiums-summary--header {
    padding-top: 20px;
}

.coverages__premiums {
    float: right;
    width: 100%;

    &.width-two-thirds {
        width: 67%;
    }
}

// Stacking for alerts and premiums
// depends on number of premium boxes
@include screensize(1010) {
    .coverages__premiums {
        float: none;
        width: 100%;
        padding: 0 10px;
    }

    .coverages__premiums.width-two-thirds {
        width: inherit;
    }

    .coverages__premiums-summary--header {
        padding-top: 0;
    } // When 2 premium boxes, stack here...
    .coverages__premiums-summary--change {
        & .coverages__premiums {
            & .premium-total-container {
                float: none;
                width: auto;
                margin: 0 0 20px;
            }
        }
    }
}

@include screensize(880) {
    .coverages__premiums-summary--nochange {
        .coverages__premiums {
            width: 100%;
        }
    }
}

@include screensize(760) {
    // Stack when 1 box
    .coverages__premiums {
        float: none;
        width: 100%;
    } 

    .coverages__premiums-summary--nochange {
        & .coverages__premiums {
            & .premium-total-container {
                float: none;
                width: auto;
                margin: 0 0 20px;
            }
        }
    }
}

@include screensize(600) {
    .premium-total-container {
        float: none;
        width: auto;
        margin: 0;
    }
}
