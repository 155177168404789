@use "@nationwide-bolt/css-core/dist/scss/tokens" as Bolt;

.margin-bottom-lg {
    margin-bottom: Bolt.$space-lg;
}

.review-submit-item {
    clear: left;
    margin-bottom: 32px;
}

.review-submit-data-label {
    float: left;
    width: 60%;
    padding-right: 40px;
    max-width: 400px;
    font-weight: bold;
}

.review-submit-data-value {
    float: left;
    width: 40%;
}

.padding-for-ul {
    padding-left: 10px;
}

@media screen and (max-width: 600px) {
    .review-submit-data-label {
        float: none;
        width: 100%;
        padding-right: 0;
        max-width: none;
    }

    .review-submit-data-value {
        float: none;
        width: 100%;
    }

    .padding-for-ul {
        padding-left: 30px;
    }
}
