@use "@nationwide-bolt/css-core/dist/scss/tokens" as Bolt;

/*--- Coverage ---*/
.coverage ul {
    font-size: 18px;
    margin: 0;
    padding: 0 40px 0 20px;
}

.coverage ul li {
    margin: 0 0 15px;
    line-height: 1.5em;
}

/*--- Policy Section ---*/
.section-description {
    font-size: 22px;
    color: Bolt.$palette-vibrantBlue;
    margin: 20px 20px 10px;
    text-align: center;
}

.disclaimer-text {
    font-size: 16px;
    margin: 2% 0;
}

.test-drive-text p {
    margin-bottom: Bolt.$space-lg;
}

/*--- Dropdown ---*/
.custom-dd-wrapper {
    position: relative;
    background-color: #fff;
    margin: 0 auto 20px;
    width: 450px;
}

.custom-dd-wrapper .custom-dd select {
    box-shadow: none;
    text-overflow: "";
    width: 100%;
    height: 56px;
    max-width: 100%;
    text-indent: 0.01px;
    border: 1px solid #c4c4c4;
    border-radius: 8px;
    font-size: 22px;
    color: #08427e !important; /* stylelint-disable-line declaration-no-important */
    padding: 3% 82px 3% 6%;
    margin: 0;
    white-space: nowrap;
    background-repeat: no-repeat;
    background-size: 75px 55px;
    background-position-x: 15px;
}

@media screen and (min-width: 480px) {
    .custom-dd-wrapper .custom-dd.vehicle .vehicle-dropdown.vehicle-dropdown-resize {
        font-size: 18px;
        padding: 3% 72px 3% 0;
        text-indent: 105px;
    }
}

.custom-dd-wrapper .custom-dd.vehicle .vehicle-dropdown {
    background-color: transparent;
    padding: 3% 82px 3% 0;
    text-indent: 105px;
}

.custom-dd select::-ms-expand {
    display: none;
}

.custom-dd-wrapper .dd-arrow {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 72px;
    background-image: url("../images/image-dropdown-arrow.png");
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
    border: 1px solid #c4c4c4;
    border-radius: 8px;
    pointer-events: none;
}

/*--- General Styles ---*/
.overflow {
    overflow: hidden;
}

.float {
    float: left;
}

.inline-block {
    display: inline-block;
}

.margin-right {
    margin-right: 20px;
}

.center {
    text-align: center;
}

/*--- Media Queries ---*/
@media screen and (max-width: 960px) {
    .float {
        float: none;
    }

    .margin-right {
        margin-right: 0;
    }
}

@media screen and (max-width: 768px) {
    .section-description {
        font-size: 20px;
    }
}

@media screen and (max-width: 670px) {
    /*--- Coverage ---*/
    .coverage ul {
        width: 100%;
        margin: 0 0 20px;
        padding: 0 0 0 20px;
    }

    .coverage ul li {
        margin: 0 0 10px;
    }
}

@media screen and (max-width: 639px) {
    .section-description {
        font-size: 18px;
    }
}

@media screen and (max-width: 479px) {
    /*--- Policy Section ---*/
    .section-description {
        margin: 20px 6%;
    }

    /*--- Dropdown ---*/

    .custom-dd-wrapper {
        width: 100%;
    }

    .custom-dd-wrapper .custom-dd.vehicle .vehicle-dropdown {
        padding-left: 25px;
        text-indent: 70px;
        font-size: 20px;
    }

    .custom-dd-wrapper .custom-dd.vehicle .vehicle-dropdown.vehicle-dropdown-resize {
        font-size: 18px;
        padding-left: 25px;
    }

    .custom-dd-wrapper .custom-dd select {
        padding: 3% 60px 3% 6%;
    }

    .custom-dd-wrapper .dd-arrow {
        width: 60px;
    }

    /*--- General Styles ---*/
    .inline-block {
        display: block;
    }
}
