//
// Sass Functions
//
@use "sass:math";

// Default browser font to use when calculating em
$browser-context: 16;

// Converts px to em
@function em($pixels, $context: $browser-context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return math.div($pixels, $context) * 1em;
}

//Bolt Override
.btn-primary:hover, .btn-primary:focus {
    color: #fff;
    background-color: #27486e;
    border-color: #1d3654;
}

.btn-primary {
    color: #fff;
    background-color: #346094;
    border-color: #2d5481;
}
