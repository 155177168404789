@use "@nationwide-bolt/css-core/dist/scss/tokens" as Bolt;

.alert {
    display: inline-block;
}

.alert p {
    display: inline-block;

    &:last-of-type {
        margin: 0;
    }
}

.alert-banner-icon {
    width: 48px;
    height: 48px;
    float: left;
    fill: #000;
    margin-right: 20px;
}

.flex-section {
    flex: 0 1 auto;
}

is-personal-policy-alert-wrapper {
    word-break: normal;
    overflow-wrap: anywhere;
    margin-bottom: Bolt.$space-sm;
    display: block;
    clear: both;
}

.media-body {
    vertical-align: middle;

    & p {
        margin-bottom: 10px;
    }
}

.field-level .alert {
    padding-right: 20px;
    width: initial;
}

.alert [class^="nw-icon-"] {
    font-size: 24px;
}

.field-level .alert .media-body {
    padding-right: 20px;
    width: initial;
}

.media {
    align-items: center;
}

.modal-open .modal {
    overflow: hidden scroll;
}

.modal-title {
    & p {
        margin: 0;
        display: inline-block;
    }
}

.modal.fade .modal-dialog {
    transform: none;
}

.modal-dialog {
    padding: 20px;
    max-width: 600px;
    width: auto;
}

.modal-header,
.modal-footer {
    display: block;
    justify-content: normal;
    align-items: normal;
}

.field-spacing .alert {
    margin-bottom: 0;
}

.service-number-text {
    display: inline;
}

.service-number-href {
    display: none;
}

.policy-status {
    margin: 20px 10px;
}

@media (min-width: 750px) {
    .modal-dialog {
        margin: 8% auto;
    }
}

@media (max-width: 736px) {
    .modal-body .media-body {
        overflow: auto;
        max-height: 250px;
    }

    .service-number-text {
        display: none;
    }

    .service-number-href {
        display: inline;
    }
}
