@use "@nationwide-bolt/css-core/dist/scss/tokens" as Bolt;

.discounts-container {
    padding: 0 10px;
    margin-bottom: 52px;

    & .alert {
        margin-bottom: 0;
    }
}

.on-your-side-box {
    padding: 0 10px;
}

.discounts-bottom {
    margin-bottom: 52px;
}

.accordion-container .accordion-item {
    margin-bottom: 0;
}

.bolt-typography {
    p {
        margin: 0;
    }

    // Styling used for spacing inside contentful messages, no not remove
    .content-spacing {
        margin-bottom: Bolt.$space-lg;
    }
}

.ni-discounts {
    .accordion-container {
        border: none;
    }

    .accordion__content {
        border: solid 0 #ccc;
    }

    .accordion__panel {
        background-color: #fff;
        border: solid 0 #efefef;
    }

    .accordion {
        margin-bottom: 0;
    }

    .panel {
        margin-bottom: 15px;
        overflow: hidden;
    }

    .accordion-container--coverages .accordion__content {
        padding: 30px 70px;
    }

    @media screen and (max-width: 450px) {
        .accordion-container {
            margin: 0 10px Bolt.$space-lg 10px;
        }

        .nw-accordion-standard .accordion-body {
            padding: Bolt.$space-lg 0 Bolt.$space-2xl;
        }
    }
}

.nw-accordion-standard .accordion-body {
    padding: Bolt.$space-2xs 0 Bolt.$space-sm;
    margin: 0;
    box-shadow: 0 0 0;
}

.nw-accordion-standard .accordion-header button:hover[aria-expanded="false"],
.nw-accordion-standard .accordion-header button:focus[aria-expanded="false"] {
    background-color: Bolt.$palette-paleGray50;
    text-decoration: none;
    transition: all 350ms;
    border-radius: 5px;
}

.nw-accordion-standard .accordion-header button:hover[aria-expanded="true"],
.nw-accordion-standard .accordion-header:not(.collapsed) button:focus[aria-expanded="true"] {
    background-color: Bolt.$palette-paleGray25;
    text-decoration: none;
    transition: all 350ms;
    border-radius: 5px;
}

@media screen and (max-width: 720px) {
    .discounts-container p:first-of-type {
        padding-bottom: Bolt.$space-2xl;
    }

    .accordion-container--coverages .discounts__icon {
        top: 20px;
        margin-top: 0;
    }
}
