@import "_mixins";

/*
	Edit Coverages Full Context view
*/

.ni-edit-coverages .coverages__premiums-summary--header .premium-total-container {
  margin-bottom: 0;
}

.ni-edit-coverages .accordion-container--coverages .coverages-details-container {
  margin-bottom: 0;
}

@include screensize(600) {
  .ni-edit-coverages .coverages__premiums-summary--header .premium-total-container {
    margin-bottom: 20px;
  }

  .ni-edit-coverages .accordion-container--coverages .coverages-details-container {
    padding-top: 0;
  }

  .coverages__premiums-summary .premium-total-container {
    float: none;
    margin-left: 20px;
    margin-right: 20px;
  }
}

.ni-edit-coverages .accordion .accordion__header--noicon {
  h3,
  .coverages__summary-container {
    padding-left: 0;
  }
}
