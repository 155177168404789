.map-height-bolt {
    height: 400px;
    width: 100%;
    display: flex;
}

@media (max-width: 736px) {
    .map-height-bolt {
        height: 400px;
        width: 100%;
        display: flex;
    }
}
