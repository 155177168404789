@use "@nationwide-bolt/css-core/dist/scss/tokens" as Bolt;

/* Custom ACCORDIONS */
.accordion-container {
    margin: 0 20px 20px;

    .panel {
        margin-bottom: 15px;
    }
}

.accordion__content {
    padding: 30px 80px 25px;
}

@media screen and (max-width: em(768px)) {
    .accordion__content {
        padding: 30px 30px 25px;
    }
}

/* Bolt Accordions Overrides for ngb-accordion integration */
.header-separator {
    border-top: 1px solid Bolt.$palette-paleGray;
    clear: both;
    color: Bolt.$palette-white;
    margin-left: 10px;
    margin-right: 10px;
}

.nw-accordion-standard :first-child :first-child :first-child .accordion-title {
    color: Bolt.$palette-charcoal;
    font-size: 18px;
    font-weight: 700;
    margin: 0.375em 0.375em 0.5em 3em;
}

.nw-accordion-standard .accordion-item {
    border: 0 solid Bolt.$palette-paleGray75;
}

.nw-accordion-standard .accordion-header {
    background-color: Bolt.$palette-white;
    border-bottom: none;
    padding: 0;
    transition: all 350ms;
}

.nw-accordion-standard .accordion-header button:hover,
.nw-accordion-standard .accordion-header button:focus {
    background-color: Bolt.$palette-paleGray25;
    text-decoration: none;
    transition: all 350ms;
}

.nw-accordion-standard .accordion-header .accordion-button {
    padding: 9px 36px 0 14px;
    display: block;
    position: relative;
    width: 100%;
    text-align: left;
    white-space: normal;
    background-color: transparent;
}

.nw-accordion-standard .accordion-header.collapsed button:before,
.nw-accordion-standard .accordion-header button:before {
    position: absolute;
    left: 0;
    content: "";
    display: none;
    top: 0;
    border-top: none;
    border-bottom-width: 0;
    border-bottom-style: none;
    border-left: none;
    border-right: none;
    margin-top: 25px;
}

.nw-accordion-standard.left-chevron .accordion-header button.collapsed:before,
.nw-accordion-standard .accordion-header button.collapsed:after {
    top: 50%;
    /* stylelint-disable-next-line declaration-property-value-disallowed-list */
    margin-top: -10px;
    transform: rotate(0deg);
}

.nw-accordion-standard.left-chevron .accordion-header button:before,
.nw-accordion-standard .accordion-header button:after {
    position: absolute;
    right: 25px;
    content: url("../images/arrow-standard.svg");
    transform: rotate(180deg);
    transition: all 350ms;
    width: 24px;
    height: 24px;
    display: inline-block;
    top: 50%;
    /* stylelint-disable-next-line declaration-property-value-disallowed-list */
    margin-top: -10px;
}

.custom-accordion-header .custom-left-chevron {
    position: absolute;
    left: 30px;
    content: url("../images/arrow-standard.svg");
    width: 24px;
    height: 24px;
    display: inline-block;
    top: 50%;
    /* stylelint-disable-next-line declaration-property-value-disallowed-list */
    margin-top: -10px;
}

.nw-accordion-standard.left-chevron .accordion-header button:before {
    margin-left: 30px;
}

.nw-accordion-standard.left-chevron .accordion-header button:after {
    content: none;
}

.nw-accordion-standard .accordion-body {
    padding: 0;
    margin: 0;
    box-shadow: 0 -1px 0 Bolt.$palette-paleGray75;
}

@media screen and (max-width: (768px)) {
    .nw-accordion-standard .accordion-body {
        margin: 0;
        font-size: initial;
    }
}

@media screen and (max-width: em(450px)) {
    .nw-accordion-standard .accordion-header button {
        padding: 14px 25px 10px 14px;
    }

    .nw-accordion-standard .accordion-header button:after {
        right: 15px;
    }

    .nw-accordion-standard .accordion-body {
        margin: 0;
        font-size: initial;
    }
}

/* Custom ACCORDIONS */
.accordion-container .accordion-item {
    margin: 0 10px 15px;
}