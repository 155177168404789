@use "@nationwide-bolt/css-core/dist/scss/tokens" as Bolt;

/* PREMIUM TOTAL */
.premium-total-container {
    float: right;
    margin: 0 20px 20px 0;
    border: none;
    padding: 15px 30px;
    border-radius: 4px;
}

@include screensize(410) {
    .premium-total-container {
        display: block;
        width: auto;
        float: none;
        margin-left: 20px;
    }
}

.premium-total__amount-container {
    text-align: center;
}

.premium-difference--increase .premium-total__primary-amount:after {
    content: $icon-arrow-up;
    color: #000000;
}

.premium-difference--decrease .premium-total__primary-amount:after {
    content: $icon-arrow-down;
    color: #000000;
}
