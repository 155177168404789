/* You can add global styles to this file, and also import other style files */
@use "@nationwide-bolt/css-core/dist/scss/tokens" as Bolt;
@import "@nationwide-bolt/css-core/dist/css/bolt-core.css";
@import "./assets/scss/screen";

.bolt_typography body {
    font-size: 18px;
}

.bolt-button-wc--size-md {
    font-size: 18px;
}
