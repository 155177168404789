@use "@nationwide-bolt/css-core/dist/scss/tokens" as Bolt;
@import "mixins";
/*
| Edit Coverages Price Picker
| Within accordion content
|
*/

// Variables
$color-active: Bolt.$palette-mediumBlue; // Blue
$int_indicatorHeight: 32px;

// Base styles; full-width
.coverage-picker a {
    color: #000;
}

.coverage-picker a:hover,
.coverage-picker a:focus {
    text-decoration: none;
}

.coverage-picker-tile {
    position: relative;
    margin: 0 5px 5px;
    padding: 0;
    list-style: none;
    float: left;
    width: 150px;
    min-height: 182px;
    text-align: center;
}

.coverage-picker-tile .btn {
    border: 1px solid #0067c5;
    color: #0067c5;
    padding: 8px 12px;
}

.coverage-picker-indicator {
    font-size: 15px;
    height: $int_indicatorHeight;
    color: #ffffff;
    padding-top: 8px;
    padding-bottom: 8px;
}

.coverage-picker-indicator-top {
    background-color: Bolt.$palette-darkBlue;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.coverage-picker-tile--link {
    position: relative;
    display: block;
    color: $color-active;
    margin-top: $int_indicatorHeight;
    padding: Bolt.$space-lg 3px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    align-items: initial;
    width: 150px;
    min-height: 150px;
    background-color: #f0f0f0;

    &:hover,
    &:focus {
        background-color: #e4e4e4;
    }
}

.coverage-picker-tile--label {
    color: Bolt.$palette-mediumBlue;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.coverage-picker-tile-checkmark {
    display: inline-block;
    position: relative;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    text-align: center;
    font-size: 11px;
    padding-top: 4px;

    & .bolt-icon-wc--size-md.is-icon {
        width: 16px;
        height: 16px;
        fill: #ffffff;
    }
}

.coverage-picker-tile.coverage-picker-tile--current .coverage-picker-tile--link,
.coverage-picker-tile .coverage-picker-tile--current .coverage-picker-tile--link {
    margin-top: 0;
}

.coverage-picker-tile.coverage-picker-tile--selected .coverage-picker-tile--link,
.coverage-picker-tile .coverage-picker-tile--selected .coverage-picker-tile--link {
    background-color: Bolt.$palette-vibrantBlue;

    &:focus {
        outline-color: #304a50;
    }

    .coverage-picker-tile--label {
        color: #fff;
    }

    .btn {
        padding: 8px 12px;
        color: #ffffff;
        background-color: Bolt.$palette-vibrantBlue;
        border-color: #ffffff;
    }
}

.coverage-picker-tile.coverage-picker-tile--current .coverage-picker-tile--link,
.coverage-picker-tile .coverage-picker-tile--current .coverage-picker-tile--link {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.coverage-picker-tile--label {
    padding: 0 6px;
    min-height: 37px;
    margin-bottom: Bolt.$space-lg;
    font-weight: bold;
    font-size: 16px;
}

// 912px - First stack point, to account for 5-wide button max
@include screensize(880) {
    .coverage-picker-tile {
        text-align: left;
        float: none;
        width: 100%;
        max-width: none;
        margin-bottom: 10px;
        min-height: 0;
    }

    .coverage-picker-tile .btn {
        width: 120px;
        margin-bottom: 0;
    }

    .coverage-picker-tile--link {
        min-height: 65px;
        width: 100%;
        margin-top: 0;
        text-align: left;
        padding: 1px;
        display: flex;
        align-items: center;
    }

    .coverage-picker-tile--label {
        min-height: 0;
        margin-bottom: 5px;
        padding-top: 0;
        display: inline-block;
        margin-left: 15px;
    }

    .coverage-picker-tile--button {
        float: left;
        margin-left: auto;
        margin-right: 15px;
    }

    button.coverage-picker-tile--link .coverage-picker-tile--button {
        min-height: 23px;
    }

    .coverage-picker-indicator {
        width: 100%;
        display: inline-block;
        text-align: center;
        padding: 5px 15px;
    }

    .coverage-picker-tile.coverage-picker-tile--current .coverage-picker-tile--link,
    .coverage-picker-tile .coverage-picker-tile--current .coverage-picker-tile--link {
        border-top-right-radius: 4px;
    }
}

// 600px - Small device
@include screensize(450) {
    .coverage-picker-tile .btn {
        width: 100%;
        padding: 0.75em 1em;
    }

    .coverage-picker-tile.coverage-picker-tile--selected .coverage-picker-tile--link .btn,
    .coverage-picker-tile .coverage-picker-tile--selected .coverage-picker-tile--link .btn {
        background-position: 5px 8px;
    }
}
