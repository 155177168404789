@use "@nationwide-bolt/css-core/dist/scss/tokens" as Bolt;
@import "_mixins";

/*
| Edit Coverages Accordion Module
| Styles for and inside the accordion content container
|
*/
/*
| Default 'screen' overrides
| Accordions, messaging and headers
|
*/
.ni-edit-coverages {
    // Accordion
    .accordion-container {
        .accordion__content,
        .accordion__panel {
            border: none;
        }

        .accordion__panel {
            border: solid 0 #efefef;
        }
    }
}

.coverages-detail-limit {
    width: 32%;
}

.coverages-detail-premium {
    width: 28%;
}

.coverages-vehicle-more-arrow {
    padding-top: 2px;
    fill: #0e6fc7;
}

@include screensize(1056) {
    /* 1056px */
    .coverages__premium {
        font-size: 20px;
    }
}

@include screensize(995) {
    /* 995px */

    .coverages-legend__name {
        width: 40%;
    }

    .coverages__title {
        width: 40%;
    }

    .coverages__detail__description {
        width: 38%;
    }

    .coverages-detail-limit {
        width: 32%;
    }

    .accordion-container--coverages .accordion__content {
        padding: 30px 40px;
    }

    .coverages__detail__item__header--vehicle {
        width: 38%;
    }

    .coverages__detail__item__header--current {
        width: 33%;
    }

    .coverages__detail__item__header--premium {
        width: 29%;
    }
}

@include screensize(820) {
    /* 820px */
    .coverages-legend__description {
        width: 35%;
    }

    .coverages-legend__premium {
        width: 25%;
    }

    .coverages__summary-container {
        width: 35%;
    }

    .coverages__premium {
        width: 25%;
    }
}

@include screensize(720) {
    /* 720px */
    #vehicle-carousel.ng-carousel-container {
        padding: 0 35px;
    }

    .coverages-legend {
        display: none;
    }

    .coverages__title {
        display: block;
        width: 100%;
    }

    .coverages__summary-container {
        display: block;
        width: 100%;
    }

    .coverages__premium {
        display: none;
    }

    .accordion-container--coverages .accordion__content {
        padding: 20px 10px;
    }

    .coverages__detail__item {
        padding: 0 0 10px;
    }
}

@include screensize(620) {
    /* 620px */
    .coverages__detail__item {
        padding: 0 0 10px;
    }

    .coverages-detail-limit {
        width: 32%;
    }

    .coverages-detail-premium {
        width: 29%;
    }
}

@include screensize(550) {
    /* 550px */
    .coverages__detail__description {
        padding-left: 0;
        width: 35%;
    }

    .coverages-detail-limit {
        width: 32%;
    }
}

@include screensize(450) {
    /* 450px */
    .coverages__title h3 {
        font-size: 22px;
    }

    .coverages__description {
        font-size: 18px;
        color: #666666;
    }

    .coverages__detail__description {
        font-size: 18px;
    }

    .coverages-detail-premium {
        font-size: 20px;
    }
}

/*
| Compare cars table
|
*/

.coverages-details-container {
    background-color: #fff;
}

.coverages__detail__item__header {
    padding: Bolt.$space-lg 0 0 0;
    font-size: 16px;
    padding-bottom: 3px;

    span {
        display: block;
        float: left;
        color: #666;
    }
}

.coverages__detail__item__header--vehicle {
    width: 38%;
}

.coverages__detail__item__header--current {
    width: 33%;
}

.coverages__detail__item__header--premium {
    width: 29%;
    text-align: right;
}

// Adjust column widths, hide car icon
@include screensize(775) {
    .coverages__detail__description,
    .coverages__detail__item__header--vehicle,
    .coverages__detail__item__header--current {
        padding-left: 0;
    }

    .coverages__detail__description {
        width: 38%;
    }

    .coverages__detail__item__header--vehicle {
        width: 38%;
    }

    .coverages__detail__item__header--current {
        width: 33%;
    }

    .coverages__detail__item__header--premium {
        width: 29%;
        text-align: right;
    }
}

// Switch to block-style for some elements
@include screensize(500) {
    .coverages__detail__item__header {
        display: none;
    }

    .coverages__detail__item {
        position: relative;
        padding: 20px 0;
        margin-bottom: 0;
    }

    .coverages__detail__description,
    .coverages-detail-limit {
        width: 100%;
        display: block;
    }

    .coverages-detail-premium {
        right: 0;
        top: 20px;
        width: 100%;
    }
}

/*
| Misc accordion content
| Copy and links
|
*/

.coverages__workspace--description {
    padding-bottom: 20px;
    min-height: 55px; // Needed so icon doesn't get clipped

    p {
        margin-bottom: 4px;
    }
}

app-personal-policy-coverages-content-description-shared .coverages__workspace--description {
    padding-bottom: Bolt.$space-sm;
}

.coverages__options {
    border-top: solid 1px #ccc;
    padding-top: Bolt.$space-2xl;
    position: relative;

    & h5 {
        font-weight: bold;
    }
}

.coverages__options .accordion__info-row--2col .accordion-info-column p {
    margin: 0;
}

.coverages__options--title {
    font-size: 20px;
    margin-bottom: Bolt.$space-2xl;
}

.coverages__options--right {
    text-align: right;
}

.coverages__options--helptext {
    font-size: 16px;
}

.coverages__options--current-coverage {
    margin-bottom: Bolt.$space-sm;
}

.coverages__options--recommended-coverage {
    margin-top: Bolt.$space-sm;
}

.coverages__premium {
    font-weight: bold;
}

.coverages-workspace-premium {
    color: #e65100;
    font-size: 22px;
    font-weight: bold;
    text-align: right;
    margin-bottom: 10px;
    display: none;
}

.coverages__workspace--carcompare-trigger,
.coverages__workspace--seemore-trigger {
    margin-top: Bolt.$space-2xl;
    display: inline-block;
    position: relative;
    text-decoration: none;
    color: Bolt.$palette-mediumBlue;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
}

.button-outline:focus[aria-expanded="false"],
.button-outline:hover[aria-expanded="false"] {
    background-color: Bolt.$palette-paleGray50;
}

.button-outline:focus[aria-expanded="true"],
.button-outline:hover[aria-expanded="true"] {
    background-color: Bolt.$palette-paleGray25;
}

.button-outline:focus {
    z-index: 3;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.coverages__footer {
    padding-top: 15px;

    .premium-total-container {
        margin-right: 0;
        background-color: #fff;
        border: none;
        min-width: 300px;
        text-align: center;
        margin-bottom: 10px;
    }
}

.coverages__footer--message {
    float: left;
    width: 50%;
}

.coverages__footer--premium {
    float: right;
    width: 48%;
    text-align: right;
}

.bolt-button.bolt-button:disabled,
.bolt-button__disabled.bolt-button {
    cursor: not-allowed;
}

.accordion-info-column {
    display: inline-block;
    float: left;
    margin: 0 0 20px;
    padding-right: 25px;
    vertical-align: top;
}

.accordion__info-row--2col .accordion-info-column {
    width: 50%;
}

@media screen and (max-width: em(450px)) {
    .accordion__info-row--2col .accordion-info-column {
        width: 100%;
    }

    input[type="radio"] {
        margin: 0;
    }
}

/* 775px */
@include screensize(775) {
    .coverages__options .accordion__info-row--2col .accordion-info-column {
        width: 100%;
    }

    .coverages__footer--message,
    .coverages__footer .premium-total-container,
    .coverages__footer--premium {
        float: none;
        width: auto;
        min-width: 0;
        margin: 0;
    }

    .coverages__footer {
        padding-top: 0;
    }

    .coverage-new-premium {
        margin-bottom: 20px;
    }

    .coverages__options--right {
        text-align: left;
    }
}

@include screensize(720) {
    .coverages-workspace-premium {
        display: block;
    }
}

@media screen and (max-width: 800px) {
    .coverages__workspace--description {
        padding-bottom: 20px;
        padding-right: 90px;
        min-height: 55px;
    }
}

@media screen and (max-width: 768px) {
    .coverages__workspace--description {
        padding-bottom: 20px;
        padding-right: 65px;
        min-height: 55px;
    }
}

@media screen and (max-width: 450px) {
    .coverages__workspace--description {
        padding-bottom: 20px;
        padding-right: 10px;
        min-height: 55px;
    }
}
