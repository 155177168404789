@use "@nationwide-bolt/css-core/dist/scss/tokens" as Bolt;

span.line {
    display: block;
}

span.line--primary {
    font-weight: bold;
    margin-bottom: 4px;
    font-size: 22px;
}

span.line--secondary {
    font-size: 16px;
    line-height: 1.3em;
}

.amount {
    font-size: 32px;
    font-weight: normal;
}

.amount-orange {
    color: #e65100;
}

.nav-options-container {
    background-color: #fff;
    border: 1px solid #c4c4c4;
    border-radius: 8px;
    overflow: hidden;
    margin: 0 10px;
}

.nav-options-container p {
    margin: 0;
}

.nav-options-container-header-container {
    width: 100%;
    display: block;
    padding: 20px 20px 0;
    padding-top: 0;
    margin-top: Bolt.$space-lg;
    margin-bottom: Bolt.$space-lg;
}

.header-container-heading {
    width: 50%;
    float: left;
    position: relative;
}

.header-container-heading h2 {
    margin-top: 7px;
    position: relative;
    font-size: 25px;
}

.header-container-heading a:hover,
.header-container-heading a:focus {
    text-decoration: underline;
}

@include screensize(600) {
    .header-container-heading h2 a {
        font-size: 24px;
    }
}

.header-container__secondary-info {
    width: 50%;
    float: right;
    text-align: right;
    margin-bottom: 15px;
}

.fixed-aws-bucket {
    position: fixed;
    top: 0;
    color: #fff;
    background-color: #039e03;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 5px 10px;
    z-index: 1500;
    font-size: 16px;
}

// Error message
$color_error: #c00;
$bgcolor_error: #fcdada;

@media screen and (max-width: 830px) {
    .header-container-heading h2 a {
        font-size: 24px;
    }
}

@media screen and (max-width: 780px) {
    .header-container-heading h2 a {
        font-size: 24px;
    }
}

@media screen and (max-width: 620px) {
    .header-container-heading h2 a {
        font-size: 24px;
    }

    .header-container-heading {
        width: 100%;
        float: none;
        display: block;
    }

    .header-container-heading::after {
        display: block;
        content: "";
        clear: left;
    }

    .header-container__secondary-info {
        width: 100%;
        display: block;
        text-align: left;
        margin-bottom: 15px;
    }

    .header-container__secondary-info .button-right {
        float: left;
        width: 65%;
        margin-top: 15px;
        margin-bottom: 20px;
    }

    .header-container__secondary-info span.break {
        display: block;
    }

    .header-container-heading h2 {
        margin-bottom: Bolt.$space-sm;
    }
}

@media screen and (max-width: 500px) {
    .header-container-heading h2 a {
        font-size: 24px;
    }
}

@media screen and (max-width: 450px) {
    .nav-options-container-header-container {
        padding: 10px 10px 0;
    }

    .header-container-heading h2 a {
        font-size: 24px;
    }

    .header-container-heading a:after {
        margin-top: 6px;
    }

    .header-container-heading h2 {
        line-height: 25px;
    }

    .header-container-heading h2 a:after {
        margin-top: 6px;
    }
}

@media screen and (max-width: 410px) {
    .header-container__secondary-info .equal-width {
        width: 100%;
    }

    .header-container-heading h2 a {
        font-size: 24px;
    }
}
