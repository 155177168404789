/* GLOBAL HEADING STYLES */
@use "@nationwide-bolt/css-core/dist/scss/tokens" as Bolt;

h1 {
    font-family: "Tiempos Headline", nw-primary, Gotham, Arial, sans-serif;
    font-size: 48px;
    color: Bolt.$palette-vibrantBlue;
    font-weight: 600;
    margin: 52px 10px Bolt.$space-2xl;
}

label.bolt-label-htmlclass {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 0;
    font-weight: bold;
    padding: 3px 0;
}

.nw-field-error#vehicle2-SelectRegisteredDrivers-error {
    margin-top: 0;
}

h2 {
    margin: 0;
    text-transform: none;
}

h3 {
    font-size: 28px;
    font-weight: normal;
    margin: 0 0 20px;
}

h4 {
    font-weight: normal;
    margin: 0 0 10px;
}

h5 {
    margin: 0 0 10px;
    font-size: 1.25rem;
}

.font-size-24 {
    font-size: 24px;
}

.bold {
    font-weight: bold;
}

/* UNIQUE HEADER STYLES */
.section-header {
    position: relative;
    padding: 15px 10px;
}

.optional span:first-child:after {
    content: " (optional)";
    font-style: italic;
    color: Bolt.$palette-charcoal;
    font-weight: 400;
}

.vin-label {
    margin-bottom: 12px;
    margin-top: 52px;
}

.control-label.required {
    margin: Bolt.$space-2xl 0 12px 0;
}

.control-label.required.vin-label {
    margin-top: 0;
}

fieldset label:not(.required) span:first-child:after,
.read-only label:not(.required) span:first-child:after {
    content: none;
}

.text-field-label.required {
    white-space: nowrap;
}

.section-header.mb0px {
    margin-bottom: 0;
}

.section-header--form {
    background: #e7e7e7;
}

.section-header h2 {
    display: inline-block;
    position: relative;
    color: #222222;
    margin: 0 5px 0 0;
}

.section-header--form h2 {
    color: #333;
    text-transform: none;
}

/* GLOBAL TEXT STYLES */
p {
    margin: 0 0 20px;
    line-height: 1.4em;
}

a {
    text-decoration: none;
    color: Bolt.$palette-vibrantBlue;
}

a:hover,
a:focus,
a:active {
    text-decoration: underline;
}

sup {
    height: 0;
    line-height: 1;
    vertical-align: baseline;
    _vertical-align: bottom;
    position: relative;
    bottom: 1ex;
    font-size: 22px;
}

hr {
    height: 1px;
    width: 100%;
    margin: 0 0 20px;
    border: none;
    background: none;
}

@media screen and (max-width: 600px) {
    h1 {
        font-size: 32px;
    }

    .section-header {
        padding: 15px 10px 10px;
    }

    .section-header--long-content h2 {
        width: 100%;
        margin-bottom: 5px;
    }

    .section-header--long-content .section-header__action {
        display: block;
        float: none;
    }

    .section-header--long-content {
        display: block;
        float: none;
        margin-left: 0;
    }
}

@media screen and (max-width: 450px) {
    h2 {
        font-size: 28px;
        margin-bottom: 10px;
    }

    .section-header--long-content h2 {
        width: 100%;
        margin-bottom: 5px;
        max-width: none;
    }
}

.nw-inline-help__content-container.open {
    max-height: 200px;
}

.nw-inline-help__content {
    padding: 10px 20px;
    max-width: none;
    max-height: 200px;

    & p {
        margin: 0;
    }

    @media screen and (max-width: 28.125em) {
        font-size: 20px;
    }

    @media screen and (min-width: 568px) {
        font-size: 15px;
    }
}

label,
.legend-label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: Bolt.$space-sm;
    font-weight: bold;
}

.info-container {
    padding: 0 10px;
    margin-bottom: 20px;
}

.page-disclaimer {
    padding: 12px 16px;
}