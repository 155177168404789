@use "@nationwide-bolt/css-core/dist/scss/tokens" as Bolt;

.manage-vehicle-selection-content-alignment {
    margin-left: 32px;
}

@media screen and (max-width: 768px) {
    .manage-vehicle-selection-content-alignment {
        margin-left: 0;
    }
}

body {
    height: 100%;
    font-size: inherit;
    color: #333;
    line-height: inherit;
    font-family: nw-primary, Gotham, "Helvetica Neue", Helvetica, sans-serif;
}

body.modal-open {
    overflow: auto;
}

// Prevent padding being added to the side of the browser window
body.modal-open[style] {
    padding-right: 0 !important; /* stylelint-disable-line declaration-no-important */
}

li {
    margin-bottom: 0;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.overlay {
    z-index: -1;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #333;
    opacity: 0;
    filter: alpha(opacity=0);
    transition: 0.3s all ease;
    -webkit-transition: 0.3s all ease;
}

a[ng-click] {
    /* Styles for anchors without href but WITH ng-click */
    cursor: pointer;
}

/* LAYOUT STYLES */

.global-container {
    font-size: inherit;
    width: 100%;
    height: 100%;
    background: #fff;
    margin: 0 auto;
    overflow: hidden;
}

.outer-container {
    width: 100%;
    height: 100%;
    transition: 0.2s all ease;
    -webkit-transition: 0.2s all ease;
}

.inner-container {
    height: 100%;
    max-width: 1200px;
    margin: 0 auto;
    overflow: visible;
}

.page-container {
    height: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.footer-container {
    background-color: Bolt.$palette-charcoal;
}

.main-container {
    min-height: 400px;
}

.form-content {
    width: 100%;
    padding: 0 10px;
    margin: 0;
    background: #fff;
    -webkit-text-size-adjust: none;
}

.clear-fix:after {
    content: "";
    display: table;
    clear: both;
}

@media screen and (max-width: 450px) {
    .main-footer {
        padding: 10px;
    }

    header.main-header.main-header--pre {
        border-bottom: none;
        margin-bottom: 0;
    }
}
