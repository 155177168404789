@use "@nationwide-bolt/css-core/dist/scss/tokens" as Bolt;
@import "mixins";

/*
* Custom CSS for angular-carousel
*/
$test-drive-carousel-height: 200px;
$test-drive-carousel-control-height: 25px;

/*--- Carousel ---*/
#test-drive-carousel {
    background: url("../images/bkg-carousel.png") repeat;
    margin: 20px 0 0;
    padding: 15px 20px 15px 0;

    @media screen and (max-width: 980px) {
        padding: 15px 40px 15px 20px;
    }
}

#test-drive-carousel .carousel-text {
    margin: 0;
    font-size: 16px;
    color: Bolt.$palette-vibrantBlue;
    font-weight: bold;
    text-transform: uppercase;
    @media screen and (max-width: 785px) {
        font-size: 18px;
    }
    @media screen and (max-width: 360px) {
        font-size: 16px;
    }
}

#test-drive-carousel .ng-carousel-wrapper {
    position: relative;
    margin: 0;
}

#test-drive-carousel .slide-wrapper {
    padding-left: 20px;
    display: inline-block;
}

#test-drive-carousel .slide:hover,
#test-drive-carousel .slide:focus {
    background-color: #e8e8e8;
}

#test-drive-carousel .active .slide {
    background-color: #cccccc;
}

#test-drive-carousel .slide {
    width: 100%;
    padding: 10px 5%;
    background-color: #ffffff;
    @media screen and (max-width: 980px) {
        padding: 15px 5%;
    }

    @media screen and (max-width: 660px) {
        padding: 10px 5% 10%;
    }
}

#test-drive-carousel .left-nav,
#test-drive-carousel .right-nav {
    background: none;
}

#test-drive-carousel .left-nav:before,
#test-drive-carousel .right-nav:before {
    content: "";
    background: url("../images/slider-left-white.png") no-repeat scroll 0 0 transparent;
    height: 25px;
    width: 20px;
    left: 0;
}

#test-drive-carousel .right-nav:before {
    background: url("../images/slider-right-white.png") no-repeat scroll 0 0 transparent;
    right: 5px;
}

#test-drive-carousel .disabled .left-nav:before,
#test-drive-carousel .disabled .right-nav:before {
    background: none;
}

#test-drive-carousel .left-nav:after,
#test-drive-carousel .right-nav:after {
    box-shadow: none;
}
