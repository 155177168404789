@use "@nationwide-bolt/css-core/dist/scss/tokens" as Bolt;

.coverage-accordion-heading {
    display: table;
    width: 100%;
    margin-bottom: Bolt.$space-sm;
    padding-left: 10px;
}

@media screen and (max-width: 720px) {
    .coverage-accordion-heading {
        display: inline-block;
        width: 100%;
        margin-bottom: Bolt.$space-sm;
        padding-left: 10px;
    }
}

.accordion-container--coverages .accordion__header:hover,
.accordion-container--coverages .accordion__header:focus,
.accordion-container--coverages .accordion__header:active {
    @include gradient(#e6e6e6, #d3d3d3);
}

.accordion-container--coverages .show-accordion .accordion__header {
    background: #d7d7d7;
}

.accordion-container--coverages .accordion__arrow {
    border-top-color: #005691;
}

.accordion-container--coverages .accordion__content {
    padding: 30px 70px;
}

.accordion-container--coverages .coverages__icon {
    position: relative;
    top: 6px;
    content: "";
}

.accordion-container--coverages .coverages__icon.nw-icon-error {
    fill: #d32426;
    font-size: 32px;
    background-color: white;
    width: 24px;
    height: 24px;
    @include border-radius(100%);

    &:before {
        content: none;
    }

    & .bolt-icon-wc--size-md.is-icon {
        width: 32px;
        height: 32px;
        fill: #d32426;
    }
}

.coverages-legend {
    margin: 0 10px Bolt.$space-lg;
    font-weight: 300;
    font-size: 16px;
    color: #666;
}

.coverages-legend__name {
    display: block;
    float: left;
    width: 40%;
}

.coverages-legend__description {
    display: block;
    float: left;
    width: 45%;
}

.coverages-legend__premium {
    display: block;
    float: left;
    text-align: right;
    width: 15%;
}

.coverages__title {
    display: table-cell;
    vertical-align: middle;
    width: 40%;
    color: #005691;
}

.coverages__title h4 {
    position: relative;
    padding: 10px 20px 10px 0;
    font-size: 18px;
    margin-bottom: 0;
    display: inline-block;
    font-weight: bold;
    color: #000000;
}

.coverages__title-red {
    display: table-cell;
    vertical-align: middle;
    width: 40%;
    color: red;
}

@media screen and (max-width: 720px) {
    .coverages__title-red {
        display: block;
        color: red;
        width: auto;
    }

    .coverage-error-icon {
        display: block;
    }
}

.table-cell {
    display: table-cell;
}

.title-icon-error {
    display: table-cell;
    padding-left: 10px;
    padding-right: 15px;
}

.coverages__summary-container {
    display: table-cell;
    vertical-align: middle;
    width: 45%;
    padding-right: 20px;
    color: #333;
}

.coverages__premium {
    display: table-cell;
    vertical-align: middle;
    width: 15%;
    font-size: 20px;
    color: #000000;
    text-align: right;
    padding-right: 50px;
}

.coverages__description {
    font-weight: 300;
    font-size: 14px;
    margin-bottom: 5px;
}

.accordion-circle {
    display: inline-block;
    position: relative;
    background-color: #ffffff;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    text-align: center;
    bottom: 4px;

    & .bolt-icon-wc--size-md.is-icon {
        width: 18px;
        height: 18px;
        fill: #347e93;
    }
}

.accordion-container {
    margin: 0;
}

.ni-edit-coverages .accordion-container--coverages .accordion__content .accordion-container--coverages .accordion__content {
    padding-bottom: 0;
}

.accordion-container .accordion-item {
    margin-bottom: 10px;
}

.accordion-container-coverages-remove-margin-bottom .accordion-item {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
}



.section-header-xl-margin-top {
    position: relative;
    margin-top: Bolt.$space-2xl;
    padding-top: 0;
}

.coverages__limit {
    display: block;
    max-width: 330px;
}

.coverages-details-container {
    margin-bottom: 20px;
    margin-top: 0;
}

.coverages__detail__item {
    position: relative;
    border-bottom: 1px solid #d7d7d7;
    padding: Bolt.$space-sm 0;
}

.coverages__detail__description {
    position: relative;
    display: block;
    float: left;
    width: 38%;
    padding: 0;
    font-weight: 300;
}

.coverages-detail-limit {
    display: block;
    float: left;
    width: 40%;
}

.coverages-detail-premium {
    display: block;
    float: right;
    width: 20%;
    text-align: right;
    font-size: 16px;
}

app-personal-policy-coverages-level-shared .accordion-container.accordion-container--coverages {
    margin-bottom: 0;
}

.coverages__options app-personal-policy-coverages-new-premium .coverages__footer {
    padding-top: 0;
}

.coverages__options app-personal-policy-coverages-new-premium .coverages__footer is-box .box-container {
    border: 2px solid #b7b7b7;
}

.coverages-main-footer__disclaimer {
    padding: Bolt.$space-2xl 20px 52px 10px;
}

.coverages-main-footer__disclaimer p {
    margin-bottom: 0;
}

.coverages-main-footer__disclaimer-top {
    margin-bottom: Bolt.$space-lg;
}

.coverages__premiums .premium-total-container {
    margin-bottom: Bolt.$space-2xl;
}

.coverages__premiums-summary--nochange is-personal-policy-alert-wrapper {
    margin-bottom: 52px;
}

.page-container app-policy-navigation bolt-tabset {
    padding: 0 12px Bolt.$space-sm;
}

.page-container hr.navigation-separator {
    margin-top: Bolt.$space-sm;
}

@media screen and (max-width: 995px) {
    .button-margin-bottom {
        margin-bottom: 10px;
    }

    .coverages-legend__name {
        width: 40%;
    }

    .coverages__title {
        width: 40%;
    }

    .coverages__detail__description {
        width: 38%;
    }

    .coverages-detail-limit {
        width: 40%;
    }

    .accordion-container--coverages .accordion__content {
        padding: 30px 40px;
    }
}

@media screen and (max-width: 820px) {
    .coverages-legend__description {
        width: 35%;
    }

    .coverages-legend__premium {
        width: 25%;
    }

    .coverages__summary-container {
        width: 40%;
    }

    .coverages__premium {
        width: 20%;
        padding-right: 20px;
    }
}

@media screen and (max-width: 720px) {
    .premium-total__amount-container .premium-total-amount-term-label {
        font-size: 18px;
    }

    .coverages__premiums .premium-total-container {
        margin-bottom: Bolt.$space-2xl;
    }

    .navigation-separator {
        margin: 0;
    }

    .accordion-container--coverages .accordion__header {
        padding: 10px 40px 10px 10px;
    }

    .coverages-workspace-premium .coverageTextCenter {
        display: none;
    }

    .coverages-legend {
        display: none;
    }

    .coverages__title {
        display: block;
        width: 100%;
    }

    .coverages__summary-container {
        display: block;
        width: 100%;
    }

    .coverages__premium {
        display: none;
    }

    .accordion-container--coverages .accordion__content {
        padding: 20px 10px;
    }

    .coverages__detail__item {
        padding: 0 20px 10px;
    }
}

@media screen and (max-width: 620px) {
    .accordion-container--coverages .accordion__arrow {
        border-width: 10px;
    }

    .coverages__detail__item {
        padding: 0 0 10px;
    }

    .coverages-detail-limit {
        width: 35%;
    }
}

@media screen and (max-width: 550px) {
    .coverages__detail__description {
        padding-left: 0;
        width: 35%;
    }

    .coverages-detail-limit {
        width: 45%;
    }
}

@media screen and (max-width: 450px) {
    .coverages__title h4 {
        font-size: 20px;
        padding-right: 10px;
    }

    .coverages__description {
        font-size: 18px;
        padding-right: 10px;
    }

    .coverages__detail__description {
        font-size: 18px;
    }

    .coverages-detail-premium {
        font-size: 20px;
        padding-right: 10px;
    }
}