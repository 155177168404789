// Bolt overrides
is-inline-help p {
    display: inline;
}

.bolt-contextual-help-wc--trigger.bolt-contextual-help {
    top: 0;
    display: inline;

    & .bolt-icon-wc--size-md.is-icon {
        width: 20px;
        height: 20px;
        fill: #326295;
    }
}
