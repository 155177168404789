@use "@nationwide-bolt/css-core/dist/scss/tokens" as Bolt;
@import "mixins";

/*
| Consider This Columns
| Used inside coverage accordion under "See more" link
|
*/

.consider-this > ul {
    margin: 0;
    padding: 0;

    & > li {
        margin: 0;
        float: left;
        width: 33.3333%;
        padding: 52px 25px 0;
        list-style: none;
    }

    & > li:first-child {
        padding-left: 0;
    }

    & > li:last-child {
        padding-right: 0;
        border: none;
    }

    ul {
        padding-left: 20px;
        list-style-type: initial;
        margin: 0;

        li {
            margin-bottom: 10px;
        }
    }
}

@include screensize(780) {
    .consider-this {
        padding-top: 20px;
    }

    .consider-this > ul > li {
        float: none;
        width: 100%;
        padding: 0;
        border-right: none;
        border-top: solid 1px #ccc;
        padding-top: 25px;

        &:first-child {
            border: none;
            padding-top: 0;
        }

        &:last-child {
            border-top: solid 1px #ccc;
        }
    }
}
