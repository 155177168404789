@use "@nationwide-bolt/css-core/dist/scss/tokens" as Bolt;
@import "mixins";

/*
| Edit Coverages Accordions
| Styles within accordion elements only, mostly the header handle
|
*/

/* Overrides */
.ni-edit-coverages {
    .accordion-container {
        border: none;
        margin-bottom: 15px;
    }

    .accordion-container--coverages .accordion__header {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .accordion-container--coverages .accordion__content {
        padding: 20px;
    }

    .accordion__content {
        border: solid 0 #ccc;
    }

    .accordion__panel {
        background-color: #fff;
        border: solid 0 #efefef;
    }

    .accordion__arrow {
        top: 45%;
    }
    /* this is new */

    .accordion {
        margin-bottom: 0;
    }

    .accordion-container--coverages a:hover,
    .accordion-container--coverages a:focus,
    .accordion-container--coverages a:active {
        text-decoration: none;
    }

    .panel {
        margin-bottom: 15px;
        overflow: hidden;
    }

    @media screen and (max-width: em(450px)) {
        .accordion-container {
            margin: 0 10px Bolt.$space-lg 10px;
        }

        .accordion-container--coverages .accordion__content {
            padding: 20px 10px;
        }

        .accordion-container--coverages .accordion__content__no__icon {
            padding: 20px 10px 20px 0;
        }

        .nw-accordion-standard .accordion-body {
            padding: Bolt.$space-lg 0 Bolt.$space-2xl;
        }
    }
}
