/* HELPERS */
.group:before,
.group:after {
    content: "";
    display: table;
}

.group:after {
    clear: both;
}

.group {
    zoom: 1;
}

.hidden {
    position: absolute;
    left: -9999em;
    top: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.clean,
.clean li,
.clean dt,
.clean dd {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
}

a.underline {
    text-decoration: underline;
}

.clear {
    clear: both;
    height: 1px;
    overflow: hidden;
}

.strong {
    font-weight: bold;
}

.button-margin {
    margin-right: 10px;
}

.right {
    float: right;
}

.left {
    float: left;
}

.js .show-overlay .overlay {
    z-index: 998;
    @include opacity(70, 0.7);
}

// Turns on overlay
.js .show-overlay .overlay-focus {
    z-index: 999;
}

.js .show-overlay .overlay-child {
    z-index: 999;
    display: block;
}

// Overrides for Bolt Bootstrap
.nw-arrow:before {
    margin: 0 0 3px;
}

.bolt-typography ul.summary-review {
    padding-left: 0;
    margin-left: 0;
}
