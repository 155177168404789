@use "@nationwide-bolt/css-core/dist/scss/tokens" as Bolt;

// Bolt overrides
.bolt-row {
    align-items: center;
    margin-right: 0;
    margin-left: 0;

    @media only screen and (max-width: 47.55em) {
        & .bolt-col:last-of-type {
            margin-top: 20px;
        }
    }
}

.content-col {
    align-items: center;
    display: flex;

    & form button {
        text-align: left;
        padding-left: 0;
    }
}

button.is-tile:hover,
button.is-tile:focus {
    cursor: pointer;
}

is-button.button-width {
    width: 225px;
    display: block;
}

.glossary-heading {
    font-size: 22px;
    margin-bottom: 8px;
    line-height: 40px;
}

.glossary-content {
    margin-bottom: Bolt.$space-sm;
    line-height: 31px;
}

.glossary-first-paragraph {
    margin-bottom: Bolt.$space-lg;
}

.font-italic {
    font-style: italic;
}

.documents-hr {
    width: 100%;
    border: 1px solid #d0d3d4;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-bottom: Bolt.$space-sm;
}

.size-48 {
    width: 48px;
    height: 48px;
}
